// 
// class that contains all of the attributes needed to write the study data and metadata records into the dynamo database
// an instance of this object is created for every file dropped into the drop box on the 'submit study' page.
//
// Each object of this type (passed as a prop) is rendered by 'XMLstudyCard.vue'
//
import { Storage } from 'aws-amplify';
import API from "@aws-amplify/api";
import StudyMAPmeta from '@/classes/StudyMAPmeta';

export default class StudyMAPfile {

    // parameters used for internal bookkeeping
    fileObject = null;
    datatype = "none";      // 'xml', 'csv'
    user = null;
    MAPstudy = null;        // copy of the file contents
    goodstudy = false;      // true if the study satisfies validation
    orderkey = 0;
    dbcheckmessage = null;
    submit_study_complete = false;  // true when the study is stored or updated in s3 and DB
    db_duplicate = 0;       // 0 = unspecified, 1 = new study, 2 = existing study
    storedMetaRecord = null;

    // parameters to write to the study data record    
    siteSlug = "none";      // used for the partition key
    filename = "none";      // used to construct the sort key
    software = "none";

    constructor(fileinfo, user) {
        this.fileObject = fileinfo;
        this.filename = fileinfo.name;

        let filenameparts = this.filename.split('.')
        let partlen = filenameparts.length
        this.datatype = filenameparts[partlen-1].toLowerCase()
        console.log(filenameparts, this.datatype)
       
        if (user) {
            this.user = user;
            this.storedMetaRecord = new StudyMAPmeta (user, this.filename, false, null);   // create a record for the metadata

            this.siteSlug = user.siteSlug;         // use the slug to avoid spaces
            this.software = user.software;

            if (this.datatype == 'xml') {
                if (this.software == 'none' || this.software == 'Invia' || this.software == 'Cedars') {
                    this.goodstudy = true;
                }
            }
            else if (this.datatype == 'csv') {
                if (this.software == 'none' || this.software == 'Cedars') {
                    this.goodstudy = true;
                }
            }
        }
    }

    addFileContents(MAPstudy) {
        if (this.goodstudy) {
            this.MAPstudy = MAPstudy;
            this.checkDB();
        }
    }

    // query the dynamo database to retrieve the metadata for the study, if it exists
    checkDB() {
        API.get('mapdata', '/check', {
            queryStringParameters: { site: this.siteSlug, studyName: this.filename }
        })
        .then(response => {
            if (response.pk && response.sk) {
                this.setStoredMetadata(response);
                this.dbcheckmessage = 'duplicate DB study';
                this.db_duplicate = 2;     // duplicate DB study
            }
            else {
                this.dbcheckmessage = 'new DB study';
                this.db_duplicate = 1;      // new DB study
            }
        })
        .catch(() => {
            this.dbcheckmessage = 'DB error';
            this.db_duplicate = 0;      // DB error
        })
    }

    // reset parameters of the metadata if the study has already been stored
    setStoredMetadata (metarecord) {
        this.storedMetaRecord.setMetaFromDB (metarecord);
        this.siteSlug = metarecord.pk;
        this.software = metarecord.software;
    }

    // submit a new study to S3 and the database
    submitNewStudy() {
        return new Promise((resolve, reject) => {
            var returnCode = {
                code: 0, 
                message: null
            };
            if (this.submit_study_complete) {
                returnCode.message = "study already stored";
                reject(returnCode);
            }
            else if (this.db_duplicate == 0) {
                returnCode.message = "study failed validation checks";
                reject(returnCode);
            }
            else {
                let writemode = 'new study';
                if (this.db_duplicate == 2) writemode = 'update study';

                let timedelay = this.orderkey*2000 + 500;
                setTimeout(() => {
                    this.writeMAPstudy(writemode).then( () => {
                        returnCode.code = 1;
                        if (this.db_duplicate == 1) {
                            returnCode.message = "study stored in DB and S3"
                        }
                        else {
                            returnCode.message = "study updated in DB and S3"
                        }
                        this.submit_study_complete = true;
                        resolve(returnCode);
                    }).catch( err => {
                        returnCode.message = err.message;
                        reject(returnCode);
                    })
                }, timedelay);
            }
        });
    }

    // write the study data to the dynamoDB database
    writeMAPstudy(writemode) {

        let data_body = {
            'siteSlug': this.siteSlug,      // defines the partition key
            'filename': this.filename,      // used to define the sort key
            'software': this.software,
            'datatype': this.datatype,
            'study_data': this.MAPstudy
        }

        return new Promise((resolve, reject) => {

            // first write the metadata record
            this.storedMetaRecord.writeMAPmeta(writemode)
                .then( () => {

                    // then write the study data record
                    API.post('mapdata', '/map', {body: data_body })
                        .then( () => {

                            // then copy the study data to S3
                            let filepath = this.siteSlug + '/' + this.filename;
                            Storage.put(filepath, this.MAPstudy)
                                .then ( () => {
                                    resolve( {
                                        code: 1,
                                        message: 'study stored in DB and S3'
                                    })
                                })
                                .catch( () => {
                                    reject({
                                        code: -2,
                                        message: 'error writing S3 data'
                                    })
                                })

                        })
                        .catch( () => {
                            reject({
                                code: -2,
                                message: 'error writing study data'
                            })
                        })

                })
                .catch( () => {
                    reject({
                        code: -1,
                        message: 'error write study metadata'
                    })
                })
        });
    }
}