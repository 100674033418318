<template>
  <div class="studyexcludeclass">
    <v-row>
      <v-col sm="3">
        <v-checkbox
          v-model="include_study"
          label="study included:"
          @change="excludeOptionChanged"
        ></v-checkbox>
      </v-col>

      <v-col>
        <v-radio-group
          v-model="exclude_category"
          @change="excludeOptionChanged"
          row
        >

          <v-radio
            label="Stess cancellation"
            value="stress cancellation"
          ></v-radio>

          <v-radio
            label="Suboptimal blood flow curve"
            value="suboptimal blood flow curve"
          ></v-radio>

          <v-radio
            label="Patient motion"
            value="patient motion"
          ></v-radio>

          <v-radio
            label="Other"
            value="other"
          ></v-radio>

        </v-radio-group>
      </v-col>

    </v-row>

  </div>

</template>

<script>
export default {
  name: "StudyExclusionOption",
  components: {},
  props: ["params"],
  data() {
    return {
      include_study: null,
      exclude_category: null,
    };
  },
  methods: {
    excludeOptionChanged() {
      this.$emit("excludeChange", {
        include_study: this.include_study,
        exclude_category: this.exclude_category,
      });
    },
  },
  computed: {},
  created() {
    if (
      this.params &&
      this.params.include_study &&
      this.params.exclude_category
    ) {
      this.include_study = this.params.include_study;
      this.exclude_category = this.params.exclude_category;
    }
  },
  mounted() {},
};
</script>

<style scoped>
.exclusiondiv {
  background-color: #f4a19c;
}
.radiogroupclass {
  margin: 0px;
  padding: 0px;
}
.studyexcludeclass {
  background-color: rgb(233, 233, 233);
}
</style>
