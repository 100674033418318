<template>

  <div class="submittedstudydiv">
    <v-btn
      block
      elevation="0"
      color="#C4DD9C"
      @click="showEditableParameters"
    >
      {{studymeta.filename}}
    </v-btn>

    <div v-if="studyDetails">

      <v-row class="cardrow">
        <v-text-field
          class="studynotetext"
          hide-details="true"
          v-model="exclude_note"
          label="Study Note"
        ></v-text-field>
      </v-row>

      <v-row class="cardrow">
        <study-exclusion-option
          :params="editParams"
          @excludeChange="excludeOptionChanged"
        />
      </v-row>

      <covid-radio-option
        :params="editParams"
        @covidChange="covidOptionChanged"
      />

      <v-row class="cardrow">
        <v-col>
          <v-btn
            small
            @click="updateStudyMetadata"
          >
            Update
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <v-btn
            small
            @click="cancelUpdateMetadata"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>

    </div>

  </div>
  <!--
  <div class="dbsubmittedstudy">

    <div v-if="studyDetails">
      <div class="row detailrow">
        <div
          class="col s6"
          v-if="abstractData"
        >
          <p>gender: {{abstractData.gender}}</p>
          <p>age: {{abstractData.age}}</p>
          <p>BMI: {{abstractData.BMI}}</p>
          <p>stress agent: {{abstractData.agent}}</p>
        </div>
    </div>
  </div>

  -->
</template>

<script>
import StudyMAPmeta from "@/classes/StudyMAPmeta";
import CovidRadioOption from "@/components/CovidRadioOption.vue";
import StudyExclusionOption from "@/components/StudyExclusionOption.vue";

export default {
  name: "SubmittedStudy",
  components: { CovidRadioOption, StudyExclusionOption },
  props: { studymeta: StudyMAPmeta },
  data() {
    return {
      studyDetails: false, // if true then show the study detail panel
      abstractData: false, // TODO:  merge the abstracted data (gender, BMI, etc) with the metadata record
      editParams: null,
      exclude_note: null,
      newCovidOption: null,
      newExcludeOption: null,
    };
  },
  methods: {
    showEditableParameters() {
      if (!this.studyDetails) {
        this.editParams = this.studymeta.getEditableParams();
        if (this.editParams.exclude_note != "none") {
          this.exclude_note = this.editParams.exclude_note;
        }
        this.studyDetails = true;
      }
    },

    updateStudyMetadata() {
      // compare the new options against the old ones to see if there has been a change
      let optionsChanged = false;

      if (this.exclude_note != this.editParams.exclude_note) {
        optionsChanged = true;
        this.editParams.exclude_note = this.exclude_note;
      }

      if (this.newExcludeOption) {
        if (
          this.editParams.include_study != this.newExcludeOption.include_study
        ) {
          optionsChanged = true;
          this.editParams.include_study = this.newExcludeOption.include_study;
        }
        if (
          this.editParams.exclude_category !=
          this.newExcludeOption.exclude_category
        ) {
          optionsChanged = true;
          this.editParams.exclude_category =
            this.newExcludeOption.exclude_category;
        }
      }
      if (this.newCovidOption) {
        if (this.editParams.covid_status != this.newCovidOption) {
          optionsChanged = true;
          this.editParams.covid_status = this.newCovidOption;
        }
      }

      if (optionsChanged) {
        this.studymeta.putEditableParams(this.editParams);
      }

      this.studyDetails = false; // hide the study detail expansion panel
    },

    // event handler invoked when the user clicks the 'cancel' button
    cancelUpdateMetadata() {
      this.studyDetails = false; // hide the study detail expansion panel
      this.exclude_note = null;
      this.editParams = null;
    },

    // match the study metadata record to a study abstract record
    findStudyAbstract() {
      if (this.userAbstracts && this.userAbstracts.length > 0) {
        for (var i = 0; i < this.userAbstracts.length; i++) {
          if (this.studymeta.filename == this.userAbstracts[i].filename) {
            return {
              BMI: this.userAbstracts[i].BMI,
              age: this.userAbstracts[i].age,
              gender: this.userAbstracts[i].gender,
              agent: this.userAbstracts[i].agent,
              scanner: this.userAbstracts[i].scanner,
            };
          }
        }
      }
      return null;
    },

    covidOptionChanged(evt) {
      this.newCovidOption = evt;
    },

    excludeOptionChanged(evt) {
      this.newExcludeOption = evt;
    },
  },
  computed: {
    userAbstracts() {
      return this.$store.getters.currentUserAbstracts;
    },
  },
  created() {
    // match the study metadata record to a study abstract record
    // this.abstractData = this.findStudyAbstract();
  },
  mounted() {
    this.studyDetails = false; // hide the study detail panel by default
  },
};
</script>

<style scoped>
.submittedstudydiv {
  padding: 2px;
}
.studynotetext {
  margin: 2px;
  padding: 2px;
}
.studyoptions {
  margin-top: 5px;
  background-color: #6cc0f4;
}
.textfielddiv {
  margin: 0;
  padding: 0;
  background-color: #ceabc0;
}
.studycontainer {
  padding: 4px;
}
.cardrow {
  margin: 0;
  background-color: rgb(233, 233, 233);
}
.bottombuttons {
  justify-content: right;
  margin-bottom: 4px;
  padding: 0;
}
.buttoncol {
  padding: 0;
}
</style>
