<!--
  This component renders the 'Submit Study' page
-->
<template>

  <v-row class="submitmasterpage">
    <v-col>
      <!-- this is the drop box area where study files are dropped -->
      <div class="dropcard">
        <drop
          class="drop"
          @drop="handleStudyFileDrop"
        >
          <p class="droptext">drop study files here</p>
        </drop>
      </div>
      <div
        class="buttondiv"
        v-if="droppedFileList.length > 0"
      >
        <v-btn
          v-if="user && user.userCanSubmit"
          @click="submitMapStudies"
          color="#E53935"
          class="white--text"
        >Submit Studies</v-btn>
      </div>
      <!-- this is the list of components displaying the dropped study files -->
      <div
        v-for="(file,index) in droppedFileList"
        :key=index
      >
        <XMLstudyCard
          :droppedStudyFile="file"
          @studyFileRecorded="acknowledgeSubmittedStudy"
        />
      </div>

      <div
        class="buttondiv"
        v-if="droppedFileList.length > 0"
      >
        <v-btn
          color="#E53935"
          class="white--text"
          @click="cancelSubmit"
        >Cancel</v-btn>
      </div>
    </v-col>
    <v-col id="StoredStudyColumn">
      <div
        id="StoredStudyDiv"
        v-if="studyMetaGroups && studyMetaGroups.length > 0"
      >
        <div
          v-for="(studyGroup,index) in studyMetaGroups"
          :key="index"
        >
          <StoredStudy
            :studyGroup="studyGroup"
            :showerrors=false
          />
        </div>
      </div>
    </v-col>
  </v-row>

</template>

<script>
import { Drop } from "vue-drag-drop";
import XMLstudyCard from "@/components/XMLstudyCard.vue";
import StoredStudy from "@/components/StoredStudy.vue";
import StudyMAPfile from "@/classes/StudyMAPfile.js";
import { dataBus } from "@/main";

export default {
  name: "SubmitStudy",
  components: { Drop, XMLstudyCard, StoredStudy },
  data() {
    return {
      droppedFileList: [],
    };
  },
  methods: {
    // event handler for the drop box
    handleStudyFileDrop(data, event) {
      event.preventDefault();
      const files = event.dataTransfer.files;

      // loop through the list of files that were dropped
      for (let i = 0; i < files.length; i++) {
        let uniq = true;
        for (let j = 0; j < this.droppedFileList.length; j++) {
          if (files.item(i).name == this.droppedFileList[j].filename) {
            uniq = false;
          }
        }

        // add only unique files to the display list
        if (uniq) {
          this.droppedFileList.push(new StudyMAPfile(files[i], this.user));
        }
      }
    },
    cancelSubmit() {
      this.droppedFileList = [];
    },

    // this is just a scheme to sequence the study file write operations.
    // each valid study file is assigned a sequential number that is used to
    // set a delay on the API calls.
    orderStudies() {
      let j = 0;
      for (let i = 0; i < this.droppedFileList.length; i++) {
        if (this.droppedFileList[i].goodstudy) {
          this.droppedFileList[i].orderkey = j;
          j = j + 1;
        }
      }
    },

    submitMapStudies() {
      this.orderStudies();
      dataBus.$emit("submitStudies"); // each 'XMLStudyCard' instance listens to the 'submitStudies' event.
    },

    acknowledgeSubmittedStudy() {
      let allstudies = true;
      for (let i = 0; i < this.droppedFileList.length; i++) {
        if (
          this.droppedFileList[i].goodstudy &&
          this.droppedFileList[i].db_duplicate == 1
        ) {
          allstudies =
            allstudies && this.droppedFileList[i].submit_study_complete;
        }
      }
      if (allstudies) {
        this.$store.dispatch("getStudyMetadata", true);
      }
    },
  },

  // when a user first navigates to this page, upload the study abstracts for the user's study site
  created() {
    this.$store.dispatch("getStudyMetadata", false);
  },
  mounted() {},
  beforeDestroy() {
    dataBus.$off();
    this.droppedFileList = [];
  },
  computed: {
    user() {
      return this.$store.getters.currentUser;
    },
    // get the study metadata groups, one for each date where studies were submitted
    // this is an array of 'StudySubmittedDay' objects
    studyMetaGroups() {
      return this.$store.getters.currentStudyMetadata;
    },
  },
};
</script>

<style scoped>
.drop {
  height: 160px;
  background-color: rgb(233, 233, 233); /*    #1abc9c;  rgb(151, 206, 175);  */
  border-radius: 24px;
  border-style: dashed;
  border-width: 2px;
  border-color: rgb(187, 186, 186);
}
.dropcard {
  margin-top: 15px;
}
.droptext {
  text-align: center;
  font-size: 28px;
  margin: 12px 0px;
  line-height: 110px;
}
.buttondiv {
  margin: 10px 0;
}

.buttondiv button {
  margin: 5px auto;
  width: 180px;
  height: 40px;
  border-radius: 8px;
  background-color: #4f8d90;
  color: white;
}

.submitmasterpage {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 0;
}
</style>
