<!--
  This component renders an object of class 'StudyMAPfile'.
  One instance of this component is created for each file dropped into the drop 
  box on the 'Submit Study' page
-->
<template>
  <v-card color="#C4DD9C">
    <p class="ptext">{{droppedStudyFile.filename}}</p>
    <div>
      <a
        class="atext"
        v-if="message"
      >{{message}} </a>
      <a
        class="atext"
        v-if="!submitAttempt"
      > -- {{ droppedStudyFile.dbcheckmessage }} </a>
      <a
        :class="storemessage(submitResult.trial)"
        v-if="submitResult && submitResult.event.message"
      > ----- {{ submitResult.event.message }} </a>
    </div>
  </v-card>
</template>

<script>
import { dataBus } from "@/main";
import StudyMAPfile from "@/classes/StudyMAPfile.js";

export default {
  name: "XMLstudyCard",
  props: {
    droppedStudyFile: StudyMAPfile,
  },
  data() {
    return {
      reader: null,
      message: null,
      submitResult: null,
      submitAttempt: false,
    };
  },
  methods: {
    storemessage: function (trial) {
      return trial ? "nosult" : "result";
    },
    logStudySubmission: function () {
      // TODO: log the study submission
      this.$emit("studyFileRecorded");
    },
  },
  computed: {},
  created() {
    // add the event listener that will respond when the user presses
    // the 'Submit Studies' or 'Update Studies' button
    dataBus.$on("submitStudies", () => {
      this.submitAttempt = true;
      this.submitResult = {
        trial: false, // true if the study submission/update is successful
        event: {},
        name: this.droppedStudyFile.filename,
      };
      if (this.droppedStudyFile && this.droppedStudyFile.goodstudy) {
        this.submitResult.event = {
          code: 0,
          message: "sending...",
        };
        this.droppedStudyFile
          .submitNewStudy()
          .then((nwevt) => {
            this.submitResult.trial = true;
            this.submitResult.event = {
              code: nwevt.code,
              message: nwevt.message,
            };
            this.logStudySubmission();
          })
          .catch((nwerr) => {
            this.submitResult.event = {
              code: nwerr.code,
              message: nwerr.message,
            };
            this.logStudySubmission();
          });
      } else {
        this.submitResult.event = {
          code: 0,
          message: "skipping file",
        };
      }
    });
  },
  mounted() {
    this.reader = new FileReader();

    // event handler triggered when the study file has been read
    this.reader.onload = (e) => {
      // add the file contents to the 'StudyMAPfile' object
      this.droppedStudyFile.addFileContents(e.target.result);

      // The 'addFileContents' routine of the 'StudyMAPfile' class checks to make sure the study file
      // is one of the accepted formats for the software being used.
      //      'Invia' software outputs an XML file
      //      'Cedars' software outputs an XML and a CSV file
      // If the file is a valid format the 'goodstudy' boolean is set true
      // No real validation of the contents is performed.
      if (this.droppedStudyFile.goodstudy) {
        this.message = "valid study file format";
      } else {
        this.message = "invalid study file format";
      }
    };

    // The 'StudyMAPfile' is created in the parent 'SubmitStudy' component and passed as a prop to this component.
    // The constructor of the 'StudyMAPfile' object sets the 'datatype' parameter.
    if (this.droppedStudyFile && this.droppedStudyFile.datatype) {
      if (this.droppedStudyFile.datatype === "xml") {
        // here is where the study file contents are read
        this.reader.readAsText(this.droppedStudyFile.fileObject);
      } else if (this.droppedStudyFile.datatype === "csv") {
        this.reader.readAsText(this.droppedStudyFile.fileObject);
      } else {
        this.message = "invalid file type";
      }
    }
  },
  beforeDestroy() {
    dataBus.$off();
  },
};
</script>

<style scoped>
.ptext {
  margin: 5px 15px;
  font-weight: bold;
}

.atext {
  margin: 5px 0 5px 15px; /* top right bottom left  */
  font-weight: bold;
  color: rgb(23, 19, 214);
}

.nosult {
  margin: 5px 0 5px 15px; /* top right bottom left  */
  font-weight: bold;
  color: rgb(3, 66, 8);
}

.result {
  margin: 5px 0 5px 15px; /* top right bottom left  */
  font-weight: bold;
  color: red;
}
</style>
