<!-- 
    This component renders a single object of type 'StudySubmittedDay'
    The object contains an array named 'metadata' containing individual studies.
    Each study is an object, containing the study metadata, retrieved from the dynamoDB database
-->
<template>
  <v-card class="studycard">
    <v-btn
      class="white--text"
      block
      elevation="0"
      color="#4B6076"
    >
      {{ studyGroup.niceDate }}
    </v-btn>

    <div v-if="studyGroup && studyGroup.metadata">
      <div
        v-for="(s,i) in studyGroup.metadata"
        :key="i"
      >
        <SubmittedStudy :studymeta="s" />
      </div>
    </div>
  </v-card>
</template>

<script>
import SubmittedStudy from "@/components/SubmittedStudy.vue";
import StudySubmittedDay from "@/classes/StudySubmittedDay.js";

export default {
  name: "StoredStudy",
  components: {
    SubmittedStudy,
  },
  props: {
    studyGroup: StudySubmittedDay,
    showerrors: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    storemessage: function (s) {
      return s.trial ? "nosult" : "result";
    },
  },
  computed: {},
  created() {},
  mounted() {},
};
</script>

<style scoped>
.studycard {
  margin-top: 15px;
  margin-left: 10px;
}
</style>
