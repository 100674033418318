<template>

  <v-row class="covidrowclass">
    <v-col
      sm="2"
      class="covidcolclass"
    >
      <p class="mycovidlabelclass">Covid:</p>
    </v-col>

    <v-col
      sm="10"
      class="covidcolclass"
    >

      <v-radio-group
        class="mycovidradioclass"
        v-model="covid_status"
        @change="covidOptionChanged"
        row
      >
        <v-radio
          class="radiooption"
          label="not disclosed"
          value="covid not disclosed"
        ></v-radio>

        <v-radio
          label="negative"
          value="covid negative"
        ></v-radio>

        <v-radio
          label="positive"
          value="covid positive"
        >
        </v-radio>
      </v-radio-group>
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: "CovidRadioOption",
  components: {},
  props: ["params"],
  data() {
    return {
      covid_status: null,
    };
  },
  methods: {
    covidOptionChanged() {
      this.$emit("covidChange", this.covid_status);
    },
  },
  computed: {},
  created() {
    if (this.params && this.params.covid_status) {
      this.covid_status = this.params.covid_status;
    }
  },
  mounted() {},
};
</script>

<style scoped>
.covidrowclass {
  margin: 0;
  background-color: rgb(233, 233, 233);
}
.covidcolclass {
  padding: 0;
}
.mycovidradioclass {
  margin-top: 0;
  padding-top: 4px;
}
.mycovidlabelclass {
  padding: 0;
  margin-top: 4px;
}
.radiorow {
  margin-top: 0px;
  padding-top: 0px;
}
.radiooption {
  margin: 0px;
}
.covidcolumn {
  margin-top: 2px;
}
</style>
